{
  "name": "@eyevinn/www",
  "version": "1.10.1",
  "description": "Eyevinn main website",
  "scripts": {
    "start": "parcel src/*.html --no-cache",
    "build": "parcel build --public-url ./ src/*.html  --no-cache",
    "postversion": "git push && git push --tags"
  },
  "author": "Eyevinn Technology <webmaster@eyevinn.se>",
  "license": "ISC",
  "devDependencies": {
    "buffer": "^6.0.3",
    "parcel": "^2.11.0",
    "prettier": "^2.5.1",
    "process": "^0.11.10"
  },
  "dependencies": {
    "@aws-sdk/client-cognito-identity": "^3.53.0",
    "@aws-sdk/client-sns": "^3.53.0",
    "@aws-sdk/credential-provider-cognito-identity": "^3.53.0"
  }
}
